exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benefits-index-jsx": () => import("./../../../src/pages/benefits/index.jsx" /* webpackChunkName: "component---src-pages-benefits-index-jsx" */),
  "component---src-pages-features-index-jsx": () => import("./../../../src/pages/features/index.jsx" /* webpackChunkName: "component---src-pages-features-index-jsx" */),
  "component---src-pages-get-in-index-jsx": () => import("./../../../src/pages/getIn/index.jsx" /* webpackChunkName: "component---src-pages-get-in-index-jsx" */),
  "component---src-pages-hero-index-jsx": () => import("./../../../src/pages/hero/index.jsx" /* webpackChunkName: "component---src-pages-hero-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-upload-index-js": () => import("./../../../src/pages/upload/index.js" /* webpackChunkName: "component---src-pages-upload-index-js" */),
  "component---src-pages-usecase-index-jsx": () => import("./../../../src/pages/usecase/index.jsx" /* webpackChunkName: "component---src-pages-usecase-index-jsx" */)
}

